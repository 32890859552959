
:root {
  --fc-daygrid-event-dot-width: 8px;
}
.fc .fc-popover {
    position: fixed;
    top: 0; /* for when not positioned yet */
    box-shadow: 0 2px 6px rgba(0,0,0,.15);
  }
.fc .fc-popover-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 3px 4px;
  }
.fc .fc-popover-title {
    margin: 0 2px;
  }
.fc .fc-popover-close {
    cursor: pointer;
    opacity: 0.65;
    font-size: 1.1em;
  }
.fc-theme-standard .fc-popover {
    border: 1px solid #ddd;
    border: 1px solid var(--fc-border-color, #ddd);
    background: #fff;
    background: var(--fc-page-bg-color, #fff);
  }
.fc-theme-standard .fc-popover-header {
    background: rgba(208, 208, 208, 0.3);
    background: var(--fc-neutral-bg-color, rgba(208, 208, 208, 0.3));
  }
/* help things clear margins of inner content */
.fc-daygrid-day-frame,
.fc-daygrid-day-events,
.fc-daygrid-event-harness { /* for event top/bottom margins */
}
.fc-daygrid-day-frame:before, .fc-daygrid-day-events:before, .fc-daygrid-event-harness:before {
  content: "";
  clear: both;
  display: table; }
.fc-daygrid-day-frame:after, .fc-daygrid-day-events:after, .fc-daygrid-event-harness:after {
  content: "";
  clear: both;
  display: table; }
.fc .fc-daygrid-body { /* a <div> that wraps the table */
    position: relative;
    z-index: 1; /* container inner z-index's because <tr>s can't do it */
  }
.fc .fc-daygrid-day.fc-day-today {
      background-color: rgba(255, 220, 40, 0.15);
      background-color: var(--fc-today-bg-color, rgba(255, 220, 40, 0.15));
    }
.fc .fc-daygrid-day-frame {
    position: relative;
    min-height: 100%; /* seems to work better than `height` because sets height after rows/cells naturally do it */
  }
.fc {

  /* cell top */

}
.fc .fc-daygrid-day-top {
    display: flex;
    flex-direction: row-reverse;
  }
.fc .fc-day-other .fc-daygrid-day-top {
    opacity: 0.3;
  }
.fc {

  /* day number (within cell top) */

}
.fc .fc-daygrid-day-number {
    position: relative;
    z-index: 4;
    padding: 4px;
  }
.fc {

  /* event container */

}
.fc .fc-daygrid-day-events {
    margin-top: 1px; /* needs to be margin, not padding, so that available cell height can be computed */
  }
.fc {

  /* positioning for balanced vs natural */

}
.fc .fc-daygrid-body-balanced .fc-daygrid-day-events {
      position: absolute;
      left: 0;
      right: 0;
    }
.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
      position: relative; /* for containing abs positioned event harnesses */
      min-height: 2em; /* in addition to being a min-height during natural height, equalizes the heights a little bit */
    }
.fc .fc-daygrid-body-natural { /* can coexist with -unbalanced */
  }
.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
      margin-bottom: 1em;
    }
.fc {

  /* event harness */

}
.fc .fc-daygrid-event-harness {
    position: relative;
  }
.fc .fc-daygrid-event-harness-abs {
    position: absolute;
    top: 0; /* fallback coords for when cannot yet be computed */
    left: 0; /* */
    right: 0; /* */
  }
.fc .fc-daygrid-bg-harness {
    position: absolute;
    top: 0;
    bottom: 0;
  }
.fc {

  /* bg content */

}
.fc .fc-daygrid-day-bg .fc-non-business { z-index: 1 }
.fc .fc-daygrid-day-bg .fc-bg-event { z-index: 2 }
.fc .fc-daygrid-day-bg .fc-highlight { z-index: 3 }
.fc {

  /* events */

}
.fc .fc-daygrid-event {
    z-index: 6;
    margin-top: 1px;
  }
.fc .fc-daygrid-event.fc-event-mirror {
    z-index: 7;
  }
.fc {

  /* cell bottom (within day-events) */

}
.fc .fc-daygrid-day-bottom {
    font-size: .85em;
    margin: 2px 3px 0;
  }
.fc .fc-daygrid-more-link {
    position: relative;
    z-index: 4;
    cursor: pointer;
  }
.fc {

  /* week number (within frame) */

}
.fc .fc-daygrid-week-number {
    position: absolute;
    z-index: 5;
    top: 0;
    padding: 2px;
    min-width: 1.5em;
    text-align: center;
    background-color: rgba(208, 208, 208, 0.3);
    background-color: var(--fc-neutral-bg-color, rgba(208, 208, 208, 0.3));
    color: #808080;
    color: var(--fc-neutral-text-color, #808080);
  }
.fc {

  /* popover */

}
.fc .fc-more-popover {
    z-index: 8;
  }
.fc .fc-more-popover .fc-popover-body {
    min-width: 220px;
    padding: 10px;
  }
.fc-direction-ltr .fc-daygrid-event.fc-event-start,
.fc-direction-rtl .fc-daygrid-event.fc-event-end {
  margin-left: 2px;
}
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  margin-right: 2px;
}
.fc-direction-ltr .fc-daygrid-week-number {
    left: 0;
    border-radius: 0 0 3px 0;
  }
.fc-direction-rtl .fc-daygrid-week-number {
    right: 0;
    border-radius: 0 0 0 3px;
  }
.fc-liquid-hack .fc-daygrid-day-frame {
    position: static; /* will cause inner absolute stuff to expand to <td> */
  }
.fc-daygrid-event { /* make root-level, because will be dragged-and-dropped outside of a component root */
  position: relative; /* for z-indexes assigned later */
  white-space: nowrap;
  border-radius: 3px; /* dot event needs this to when selected */
  font-size: .85em;
  font-size: var(--fc-small-font-size, .85em);
}
/* --- the rectangle ("block") style of event --- */
.fc-daygrid-block-event .fc-event-time {
    font-weight: bold;
  }
.fc-daygrid-block-event .fc-event-time,
  .fc-daygrid-block-event .fc-event-title {
    padding: 1px;
  }
/* --- the dot style of event --- */
.fc-daygrid-dot-event {
  display: flex;
  align-items: center;
  padding: 2px 0

}
.fc-daygrid-dot-event .fc-event-title {
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0; /* important for allowing to shrink all the way */
    overflow: hidden;
    font-weight: bold;
  }
.fc-daygrid-dot-event:hover,
  .fc-daygrid-dot-event.fc-event-mirror {
    background: rgba(0, 0, 0, 0.1);
  }
.fc-daygrid-dot-event.fc-event-selected:before {
    /* expand hit area */
    top: -10px;
    bottom: -10px;
  }
.fc-daygrid-event-dot { /* the actual dot */
  margin: 0 4px;
  box-sizing: content-box;
  width: 0;
  height: 0;
  border: 4px solid #3788d8;
  border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid var(--fc-event-border-color, #3788d8);
  border-radius: 4px;
  border-radius: calc(var(--fc-daygrid-event-dot-width, 8px) / 2);
}
/* --- spacing between time and title --- */
.fc-direction-ltr .fc-daygrid-event .fc-event-time {
    margin-right: 3px;
  }
.fc-direction-rtl .fc-daygrid-event .fc-event-time {
    margin-left: 3px;
  }
